<template>
  <TTView>
    <VRow>
      <VCol>
        <UserCard
          :user="user"
          :person-meta="personMeta"
          :pms-meta="pmsMeta"
          :sections-meta="sectionsMeta"
          :user-id="userId"
          @account:delete="handleDeleteAccount"
          @phone:add="handleNewPhoneWithoutVerification"
          @email:add="handleNewEmailWithoutVerification"
          @drop:password="handleDropPassword"
          @activate="handleActivateUser"
          @deactivate="handleDeactivateUser"
        />
      </VCol>
    </VRow>
    <VRow
      v-if="isUserActive"
      class="pb-8"
    >
      <VCol>
        <h3 class="py-3">
          Штатные должности
        </h3>

        <VDataTable
          v-for="company in companies"
          :key="company.id"
          class="mt-8"
          :items="staffPositionsCompanyFilter(company)"
          :headers="usersStaffPositionsHeaders"
          :loading="usersStaffPositionsLoading"
          hide-default-footer
        >
          <template #top>
            <div class="d-flex mb-2">
              <RouterLink
                :to="{
                  name : Names.R_ACCOUNT_V2_COMPANY_VIEW_LICENSE,
                  params : {
                    accountId : company.accountId,
                    companyId : company.id
                  }
                }"
              >
                {{ company.name }}
              </RouterLink>
              <div class="ml-5">
                <TTChip
                  v-if="company.active"
                  class="tt-light-green pale tt-light-green--text"
                >
                  Активная
                </TTChip>
                <TTChip
                  v-else
                  class="tt-light-red pale tt-light-red--text"
                >
                  Не активная
                </TTChip>
              </div>
              <div class="ml-5">
                {{ company.id }}
              </div>
            </div>
          </template>
          <template #item.name="{ item }">
            <RouterLink
              :to="{
                name : Names.R_ORGSTRUCTURE_POSITION_EDIT,
                params : {
                  positionId : item.id
                }
              }"
            >
              {{ item.name }}
            </RouterLink>
          </template>
          <template #item.teamName="{ item }">
            <RouterLink
              :to="{
                name : Names.R_ORGSTRUCTURE_COMPANY_TEAM,
                params : {
                  companyId : item.companyId,
                  teamId : item.teamId
                }
              }"
            >
              {{ item.teamName || item.teamDisplayName }}
            </RouterLink>
          </template>
          <template #item.main="{ item }">
            <VIcon
              v-if="item.main"
              color="warning"
              small
            >
              fas fa-star
            </VIcon>
          </template>
          <template #item.actions="{ item }">
            <VTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  color="tt-light-blue"
                  icon
                  small
                  class="mx-1"
                  v-bind="attrs"
                  :to="{
                    name : Names.R_ORGSTRUCTURE_USER_COMPANY,
                    params : {
                      personId : userId,
                      companyId : company.id,
                      staffPosition : item.id
                    },
                    query : {
                      staffPosition : item.id
                    }
                  }"
                  v-on="on"
                >
                  <VIcon small>
                    fal fa-network-wired
                  </VIcon>
                </VBtn>
              </template>

              <span>Связи ШД</span>
            </VTooltip>
          </template>
        </VDataTable>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { DEFAULT_USER } from '../../components/authentication/common';
import { DEFAULT_PERSON_META, DEFAULT_PMS_META, DEFAULT_SECTIONS_META } from '../../components/meta/common';
import UserCard from '../../components/authentication/UserCard.vue';

export default {
  name: 'User',

  components: {
    UserCard,
  },

  inject: ['Names'],

  data() {
    return {
      user: { ...DEFAULT_USER },
      personMeta: { ...DEFAULT_PERSON_META },
      pmsMeta: { ...DEFAULT_PMS_META },
      sectionsMeta: { ...DEFAULT_SECTIONS_META },
      usersStaffPositions: [],
      usersStaffPositionsLoading: false,
      usersStaffPositionsHeaders: [
        {
          text: 'ID ШД',
          value: 'id',
          width: '20%',
        },
        {
          text: 'ШД',
          value: 'name',
          width: '30%',
        },
        {
          text: 'Команда',
          value: 'teamName',
          width: '25%',
        },
        {
          text: 'Основная ШД',
          value: 'main',
          align: 'center',
          sortable: false,
        },
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false,
        },
      ],
      companies: [],
    };
  },

  computed: {
    userId() {
      return this.$route.params.userId;
    },
    isUserActive() {
      return this.user.active;
    },
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        const { userId: id } = this.$route.params;
        const data = { id };
        this.user = await this.$di.api.Authentication.getUser(data);
      } catch (err) {
        this.$di.notify.errorHandler(err);
        this.$di.redirect.errorHandler404(err);
      } finally {
        // no-finally
      }

      try {
        this.usersStaffPositionsLoading = true;
        const { companyIds = [] } = await this.$di.api.Orgstructure.peopleGetPersonCompanies(this.userId);

        // Note: метод getCompany возвращает promise
        const companyPromises = companyIds.map((id) => this.getCompany(id));

        this.companies = await Promise.all(companyPromises);

        const { staffPositions } = await this.$di.api.Orgstructure.getStaffPositionsByUser({ userId: this.userId });
        this.usersStaffPositions = staffPositions;
      } catch (error) {
        this.$di.notify.errorHandler(error);
      } finally {
        this.usersStaffPositionsLoading = false;
      }

      try {
        const response = await this.$di.api.Meta.getMeta({ id: this.userId, serviceId: 'person' });

        this.personMeta = JSON.parse(response.meta);
      } catch (err) {
        //
      } finally {
        // no-finally
      }

      try {
        const response = await this.$di.api.Meta.getMeta({ id: this.userId, serviceId: 'pms' });

        this.pmsMeta = JSON.parse(response.meta);
      } catch (err) {
        //
      } finally {
        // no-finally
      }

      try {
        const response = await this.$di.api.Meta.getMeta({ id: this.userId, serviceId: 'sections' });

        this.sectionsMeta = JSON.parse(response.meta);
      } catch (err) {
        //
      } finally {
        // no-finally
      }
    },

    async getCompany(id) {
      try {
        const { company } = await this.$di.api.Account.getCompany({ id });
        return company;
      } catch (error) {
        this.$di.notify.errorHandler(error);
        return {};
      }
    },

    staffPositionsCompanyFilter(company) {
      return this.usersStaffPositions.filter((el) => el.companyId === company.id);
    },

    async handleDropPassword(id) {
      try {
        const data = { id };
        await this.$di.api.Authentication.DropPassword(data);
        await this.fetch();
        this.$di.notify.snackSuccess('Пароль успешно сброшен');
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        // no-finally
      }
    },

    async handleActivateUser(id) {
      try {
        const data = { id };
        await this.$di.api.Authentication.ActivateUser(data);
        await this.fetch();
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        // no-finally
      }
    },

    async handleDeactivateUser(id) {
      try {
        const data = { id };
        await this.$di.api.Authentication.DeactivateUser(data);
        await this.fetch();
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        // no-finally
      }
    },

    handleNewPhoneWithoutVerification(userId) {
      this.$router.push({
        name: this.Names.R_AUTHENTICATION_USER_PHONES_CREATE,
        params: { userId },
      });
    },

    handleNewEmailWithoutVerification(userId) {
      this.$router.push({
        name: this.Names.R_AUTHENTICATION_USER_EMAILS_CREATE,
        params: { userId },
      });
    },

    async handleDeleteAccount(account) {
      try {
        const data = account;
        await this.$di.api.Authentication.DeleteAccount(data);
        await this.fetch();
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        // no-finally
      }
    },
  },
};
</script>
