const DEFAULT_PERSON_META = {
  name: {
    first: null,
    middle: null,
    last: null,
  },
  dob: null,
  gender: null,
};

const DEFAULT_PMS_META = {
  user_category: null,
};

const DEFAULT_SECTIONS_META = {
  person: {
    city: null,
    country: null,
    language: null,
    custom_field_2: null,
    custom_field_1: null,
    job_employment_type: null,
    job_position_type: null,
    custom_field_4: null,
    custom_field_5: null,
    job_grade: null,
    education_level: null,
    job_class: null,
    team_name: null,
    custom_field_3: null,
  },
  job: {
    start_date: null,
  },
};

export { DEFAULT_PERSON_META, DEFAULT_PMS_META, DEFAULT_SECTIONS_META };
