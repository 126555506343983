var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VCard',[_c('VToolbar',{attrs:{"flat":""}},[(!_vm.noActions)?_c('VBtn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('VIcon',[_vm._v("fal fa-chevron-left")])],1):_vm._e(),_c('VToolbarTitle',[_vm._t("header",function(){return [_vm._v(" "+_vm._s(_vm.fio)+" ")]})],2),_c('VSpacer'),(!_vm.noActions)?[(_vm.$route.name !== _vm.Names.R_AUTHENTICATION_USER)?_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","dark":"","exact":"","color":"blue","to":{ name : _vm.Names.R_AUTHENTICATION_USER, params : { userId : _vm.$route.params.userId } }}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-eye ")])],1)]}}],null,false,1670239252)},[_c('span',[_vm._v("Просмотр")])]):_vm._e(),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","dark":"","exact":"","color":"green","to":{ name : _vm.Names.R_AUTHENTICATION_ADD_SAP_ID, params : { userId : _vm.$route.params.userId } }}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-id-card-alt ")])],1)]}}],null,false,3844840407)},[_c('span',[_vm._v("Добавить SAP ID")])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","dark":"","exact":"","color":"orange","to":{ name : _vm.Names.R_AUTHENTICATION_USER_EDIT, params : { userId : _vm.$route.params.userId } }}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-edit ")])],1)]}}],null,false,4258982588)},[_c('span',[_vm._v("Редактировать данные")])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","dark":"","exact":"","color":"green"},on:{"click":function($event){return _vm.$emit('phone:add', _vm.$route.params.userId)}}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-phone-plus ")])],1)]}}],null,false,104064223)},[_c('span',[_vm._v("Новый телефон")])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","dark":"","exact":"","color":"blue","to":{ name : _vm.Names.R_AUTHENTICATION_USER_PUSH_SEND, params : { userId : _vm.$route.params.userId } }}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-comments ")])],1)]}}],null,false,444255567)},[_c('span',[_vm._v("Отправить push уведомление")])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","dark":"","exact":"","color":"green"},on:{"click":function($event){return _vm.$emit('email:add', _vm.$route.params.userId)}}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-envelope ")])],1)]}}],null,false,628946666)},[_c('span',[_vm._v("Новый email")])]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","dark":"","exact":"","color":"red"},on:{"click":function($event){return _vm.$emit('drop:password', _vm.$route.params.userId)}}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-user-secret ")])],1)]}}],null,false,1691773853)},[_c('span',[_vm._v("Сбросить пароль")])]),(_vm.user.active === false || !_vm.user.active)?_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","dark":"","exact":"","color":"green"},on:{"click":function($event){return _vm.$emit('activate', _vm.$route.params.userId)}}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-user-unlock ")])],1)]}}],null,false,2843675622)},[_c('span',[_vm._v("Активировать")])]):_vm._e(),(_vm.user.active === true)?_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","dark":"","exact":"","color":"red"},on:{"click":function($event){return _vm.$emit('deactivate', _vm.$route.params.userId)}}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-user-lock ")])],1)]}}],null,false,2590349876)},[_c('span',[_vm._v("Деактивировать")])]):_vm._e()]:_vm._e()],2),_c('VDivider'),_c('VCardText',{staticClass:"d-flex pa-0"},[_c('div',{staticClass:"list-container pa-4 pt-3"},_vm._l((_vm.user.accounts),function(account){return _c('div',{key:account.login,staticClass:"list-item d-flex mt-1 mb-1"},[_c('div',{staticClass:"item-type tt-light-mono-46--text"},[_vm._v(" "+_vm._s(account.type)+" ")]),_c('div',{staticClass:"item-login ml-5"},[_vm._v(" "+_vm._s(account.login)+" ")]),_c('VTooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","small":"","dark":"","exact":"","color":"red"},on:{"click":function($event){return _vm.handleDeleteAccount(account)}}},'VBtn',attrs,false),on),[_c('VIcon',{attrs:{"small":""}},[_vm._v(" fal fa-times ")])],1)]}}],null,true)},[_c('span',[_vm._v("Удалить")])])],1)}),0),_c('div',{staticClass:"card-right-side pt-4"},[_c('div',{staticClass:"user-uuid pr-6"},[_vm._v(" "+_vm._s(_vm.userId)+" ")]),_c('VExpansionPanels',{attrs:{"accordion":"","flat":""}},[_c('VExpansionPanel',[_c('VExpansionPanelHeader',[_vm._v("JSON PERSON META")]),_c('VExpansionPanelContent',[_c('code',{staticClass:"elevation-0 d-block"},[_vm._v(_vm._s(_vm.personMeta))])])],1),_c('VExpansionPanel',[_c('VExpansionPanelHeader',[_vm._v("JSON PMS META")]),_c('VExpansionPanelContent',[_c('code',{staticClass:"elevation-0 d-block"},[_vm._v(_vm._s(_vm.pmsMeta))])])],1),_c('VExpansionPanel',[_c('VExpansionPanelHeader',[_vm._v("JSON SECTIONS META")]),_c('VExpansionPanelContent',[_c('code',{staticClass:"elevation-0 d-block"},[_vm._v(_vm._s(_vm.sectionsMeta))])])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }