<template>
  <VCard>
    <VToolbar flat>
      <VBtn
        v-if="!noActions"
        icon
        @click="$router.go(-1)"
      >
        <VIcon>fal fa-chevron-left</VIcon>
      </VBtn>

      <VToolbarTitle>
        <slot name="header">
          {{ fio }}
        </slot>
      </VToolbarTitle>

      <VSpacer />

      <template v-if="!noActions">
        <VTooltip
          v-if="$route.name !== Names.R_AUTHENTICATION_USER"
          left
        >
          <template #activator="{ on, attrs }">
            <VBtn
              icon
              small
              dark
              exact
              color="blue"
              class="mx-1"
              :to="{ name : Names.R_AUTHENTICATION_USER, params : { userId : $route.params.userId } }"
              v-bind="attrs"
              v-on="on"
            >
              <VIcon small>
                fal fa-eye
              </VIcon>
            </VBtn>
          </template>

          <span>Просмотр</span>
        </VTooltip>

        <VTooltip left>
          <template #activator="{ on, attrs }">
            <VBtn
              icon
              small
              dark
              exact
              color="green"
              class="mx-1"
              v-bind="attrs"
              :to="{ name : Names.R_AUTHENTICATION_ADD_SAP_ID, params : { userId : $route.params.userId } }"
              v-on="on"
            >
              <VIcon small>
                fal fa-id-card-alt
              </VIcon>
            </VBtn>
          </template>

          <span>Добавить SAP ID</span>
        </VTooltip>

        <VTooltip left>
          <template #activator="{ on, attrs }">
            <VBtn
              icon
              small
              dark
              exact
              color="orange"
              class="mx-1"
              v-bind="attrs"
              :to="{ name : Names.R_AUTHENTICATION_USER_EDIT, params : { userId : $route.params.userId } }"
              v-on="on"
            >
              <VIcon small>
                fal fa-edit
              </VIcon>
            </VBtn>
          </template>

          <span>Редактировать данные</span>
        </VTooltip>

        <VTooltip left>
          <template #activator="{ on, attrs }">
            <VBtn
              icon
              small
              dark
              exact
              color="green"
              class="mx-1"
              v-bind="attrs"
              v-on="on"
              @click="$emit('phone:add', $route.params.userId)"
            >
              <VIcon small>
                fal fa-phone-plus
              </VIcon>
            </VBtn>
          </template>

          <span>Новый телефон</span>
        </VTooltip>

        <VTooltip left>
          <template #activator="{ on, attrs }">
            <VBtn
              icon
              small
              dark
              exact
              color="blue"
              class="mx-1"
              v-bind="attrs"
              :to="{ name : Names.R_AUTHENTICATION_USER_PUSH_SEND, params : { userId : $route.params.userId } }"
              v-on="on"
            >
              <VIcon small>
                fal fa-comments
              </VIcon>
            </VBtn>
          </template>

          <span>Отправить push уведомление</span>
        </VTooltip>

        <VTooltip left>
          <template #activator="{ on, attrs }">
            <VBtn
              icon
              small
              dark
              exact
              color="green"
              class="mx-1"
              v-bind="attrs"
              v-on="on"
              @click="$emit('email:add', $route.params.userId)"
            >
              <VIcon small>
                fal fa-envelope
              </VIcon>
            </VBtn>
          </template>

          <span>Новый email</span>
        </VTooltip>

        <VTooltip left>
          <template #activator="{ on, attrs }">
            <VBtn
              icon
              small
              dark
              exact
              color="red"
              class="mx-1"
              v-bind="attrs"
              v-on="on"
              @click="$emit('drop:password', $route.params.userId)"
            >
              <VIcon small>
                fal fa-user-secret
              </VIcon>
            </VBtn>
          </template>

          <span>Сбросить пароль</span>
        </VTooltip>

        <VTooltip
          v-if="user.active === false || !user.active"
          left
        >
          <template #activator="{ on, attrs }">
            <VBtn
              icon
              small
              dark
              exact
              color="green"
              class="mx-1"
              v-bind="attrs"
              v-on="on"
              @click="$emit('activate', $route.params.userId)"
            >
              <VIcon small>
                fal fa-user-unlock
              </VIcon>
            </VBtn>
          </template>

          <span>Активировать</span>
        </VTooltip>

        <VTooltip
          v-if="user.active === true"
          left
        >
          <template #activator="{ on, attrs }">
            <VBtn
              icon
              small
              dark
              exact
              color="red"
              class="mx-1"
              v-bind="attrs"
              v-on="on"
              @click="$emit('deactivate', $route.params.userId)"
            >
              <VIcon small>
                fal fa-user-lock
              </VIcon>
            </VBtn>
          </template>

          <span>Деактивировать</span>
        </VTooltip>
      </template>
    </VToolbar>

    <VDivider />

    <VCardText class="d-flex pa-0">
      <div class="list-container pa-4 pt-3">
        <div
          v-for="account in user.accounts"
          :key="account.login"
          class="list-item d-flex mt-1 mb-1"
        >
          <div class="item-type tt-light-mono-46--text">
            {{ account.type }}
          </div>
          <div class="item-login ml-5">
            {{ account.login }}
          </div>
          <VTooltip left>
            <template #activator="{ on, attrs }">
              <VBtn
                icon
                small
                dark
                exact
                color="red"
                class="mx-1"
                v-bind="attrs"
                v-on="on"
                @click="handleDeleteAccount(account)"
              >
                <VIcon small>
                  fal fa-times
                </VIcon>
              </VBtn>
            </template>

            <span>Удалить</span>
          </VTooltip>
        </div>
      </div>

      <div class="card-right-side pt-4">
        <div class="user-uuid pr-6">
          {{ userId }}
        </div>

        <VExpansionPanels
          accordion
          flat
        >
          <VExpansionPanel>
            <VExpansionPanelHeader>JSON PERSON META</VExpansionPanelHeader>
            <VExpansionPanelContent>
              <code class="elevation-0 d-block">{{ personMeta }}</code>
            </VExpansionPanelContent>
          </VExpansionPanel>
          <VExpansionPanel>
            <VExpansionPanelHeader>JSON PMS META</VExpansionPanelHeader>
            <VExpansionPanelContent>
              <code class="elevation-0 d-block">{{ pmsMeta }}</code>
            </VExpansionPanelContent>
          </VExpansionPanel>
          <VExpansionPanel>
            <VExpansionPanelHeader>JSON SECTIONS META</VExpansionPanelHeader>
            <VExpansionPanelContent>
              <code class="elevation-0 d-block">{{ sectionsMeta }}</code>
            </VExpansionPanelContent>
          </VExpansionPanel>
        </VExpansionPanels>
      </div>
    </VCardText>
  </VCard>
</template>

<script>
import { DEFAULT_PERSON_META, DEFAULT_PMS_META, DEFAULT_SECTIONS_META } from '../meta/common';
import { DEFAULT_USER } from './common';

export default {
  name: 'UserCard',

  inject: ['Names'],

  props: {
    user: {
      type: Object,
      default: () => ({ ...DEFAULT_USER }),
    },

    userId: {
      type: String,
      default: '',
    },

    personMeta: {
      type: Object,
      default: () => ({ ...DEFAULT_PERSON_META }),
    },

    pmsMeta: {
      type: Object,
      default: () => ({ ...DEFAULT_PMS_META }),
    },

    sectionsMeta: {
      type: Object,
      default: () => ({ ...DEFAULT_SECTIONS_META }),
    },

    noActions: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    fio() {
      const { user } = this;
      return `${user.firstName} ${user.middleName} ${user.lastName}`;
    },
  },

  methods: {
    handleDeleteAccount(account) {
      this.$emit('account:delete', {
        id: this.$route.params.userId,
        login: account.login,
        type: account.type,
      });
    },
  },
};
</script>

<style scoped lang="scss">
  .card-right-side {
    width: 60%;
  }

  .list-container {
    width: 40%;
    border-right: 1px solid map-get($tt-light-mono-8, 'base');
  }

  .list-item {
    align-items: center;
  }

  .item-type {
    width: 90px;
  }

  .item-login {
    width: 250px;
  }

  .user-uuid {
    text-align: right;
  }

  ::v-deep {
    .v-expansion-panel .v-expansion-panel-header {
      justify-content: right;
      .v-expansion-panel-header__icon {
        margin-left: 12px;
        .v-icon {
          font-size: 18px;
        }
      }
    }
  }
</style>
